<template>
  <div>
    <button
      v-if="currentIsAdmin === 1"
      class="btn btn-primary"
      data-target="#createEventModal"
      data-toggle="modal"
      @click="resetForms"
    >
      Create Event
    </button>

    <div
      class="modal fade overflow-hidden"
      id="createEventModal"
      tabindex="-1"
      role="dialog"
      ref="topSection"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog product-cart-modal" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-left-wrapper">
              <div class="left-content">
                <div class="modal-header p-0">
                  <span class="modal-title" id="exampleModalLabel">Create Event</span>
                  <button
                    type="button"
                    class="close-modal d-block d-md-none"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeEventModal"
                    @click="resetForm"
                  >
                    <img src="../../../../assets/images/cross.png" alt="" />
                  </button>
                </div>
                <div class="event-image my-3">
                  <div class="event-image-edit">
                    <img
                      v-if="this.eventAvatar.length == 0"
                      src="../../../../assets/event-avatar.png"
                      alt=""
                      class="default-image"
                    />

                    <div class="new-event-image" v-else-if="this.eventAvatar.length > 0">
                      <clipper-basic
                        :src="this.rawData"
                        preview="preview"
                        :border="4"
                        :outline="0"
                        :corner="false"
                        :ratio="1"
                        :wrap-ratio="1"
                         :init-width="100"
                        :init-height="100"
                        ref="clipper"
                      ></clipper-basic>
                    </div>
                    <div class="profile-photo-edit-icon-wrapper" @click="openinput()">
                      <img src="../../../../assets/images/edit.png" alt="" />
                    </div>
                  </div>
                  <div class="preview-image">
                    <div v-if="this.eventAvatar.length > 0">
                      <clipper-preview name="preview"></clipper-preview>
                    </div>
                    <div
                      v-else-if="this.eventAvatar.length == 0"
                      class="no-image-wrapper"
                    >
                      <div slot="placeholder">No image</div>
                    </div>
                  </div>
                </div>
                <div class="event-detail">
                  <div class="container-fluid no-pdd">
                    <div class="row">
                      <!-- <div
                        class="col-sm-12"
                        v-if="
                          selectedRoomTeam.sportType == 'Softball (Slow Pitch)' ||
                          selectedRoomTeam.sportType == 'Softball (Fast Pitch)' ||
                          selectedRoomTeam.sportType == 'Softball'
                        "
                      >
                        <div class="form-group">
                          <label for="eventName">Event Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="eventName"
                            v-model="eventName"
                            @keyup="errorNull"
                            required
                            maxlength="100"
                          />
                        </div>
                      </div> -->
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="eventName">Event Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="eventName"
                            v-model="eventName"
                            @keyup="errorNull"
                            required
                            maxlength="100"
                          />
                        </div>
                      </div>
                      <!-- <div
                        class="col-sm-12 col-md-6"
                        v-if="
                          selectedRoomTeam.sportType == 'Softball (Slow Pitch)' ||
                          selectedRoomTeam.sportType == 'Softball (Fast Pitch)' ||
                          selectedRoomTeam.sportType == 'Softball'
                        "
                      >
                        <div class="">
                          <label class="typo__label" for="ajaxsingle">Associations</label>
                          <multiselect
                            v-model="association"
                            id="ajaxsingle"
                            label="association_name"
                            track-by="id"
                            placeholder="Type to search"
                            open-direction="bottom"
                            :options="associationsAdd"
                            :searchable="true"
                            :loading="isLoading"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="asyncFind"
                            @change="errorNull"
                          >
                            <template slot="tag" slot-scope="{ option, remove }">
                              <span class="custom__tag"
                                >&nbsp;<span> {{ option.association_name }}</span
                                ><span class="custom__remove" v-on:click="remove(option)"
                                  >❌</span
                                ></span
                              ></template
                            >
                            <template slot="clear" slot-scope="props">
                              <div
                                class="multiselect__clear"
                                v-if="association.length"
                                @mousedown.prevent.stop="clearAll(props.search)"
                              ></div> </template
                            ><span slot="noResult"
                              >Oops! No Association found. Consider changing the search
                              query.</span
                            >
                            <span slot="noOptions"> No Association found </span>
                            <template slot="option" slot-scope="{ option }">
                              <div class="option__desc">
                                <span> {{ option.association_name }}</span>
                              </div>
                            </template>
                          </multiselect>
                        </div>
                      </div> -->

                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label class="typo__label" for="ajaxsingle">Event Type</label>
                          <select
                            class="form-control form-control-lg"
                            id="eventType"
                            v-model="eventType"
                            @change="errorNull"
                          >
                            <option value="" selected disabled>Event Type</option>
                            <option v-for="option in eventTypeOptions" :value="option.value" :key="option.value">
                                {{ option.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                        <div class="form-group pdd-adjust-type">
                          <label class="typo__label" for="ajaxsingle"
                            >Select Time Zone</label
                          >
                          <select
                            class="form-control form-control-lg"
                            id="eventType"
                            v-model="time_zone"
                            @change="errorNull"
                          >
                            <!-- <option value="" selected disabled>Time Zone</option> -->

                            <!-- Add a custom option for the user's device timezone -->
                            <option selected value="">{{ formattedDeviceTimeZone }}</option>
                            <option
                              :value="country.gmt + ' ' + country.name"
                              v-for="country in timeZoneList"
                              :key="country.name"
                            >
                              {{ country.gmt }} {{ country.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      
                      <div class="col-sm-12 col-md-6 ">
                        <div class="form-group">
                          <div class="d-flex justify-content-between align-items-center">
                            <label for="startDateCheck">Start Date</label>
                            <span
                              class="label-type error event-error start-error ml-2"
                              v-if="startDateError != ''"
                            >
                              Please select Start Date.
                            </span>
                            <div>
                              <label for="allDayEvent" class="pr-2">All Day Event</label>
                              <toggle-button
                              class="toogle m-0"
                              v-model="allDay"
                              @change="showHideTimeDiv"
                              :value="false"
                              :sync="true"
                              :width="34"
                              ref="toggleBtn"
                            />
                            </div>
                          </div>
                          <date-picker
                            :open="open"
                            @close="open = false"
                            :editable="false"
                            :clearable="false"
                            @click.native="handleClickStart"
                            v-model="startDate"
                            valueType="format"
                            format="YYYY-MM-DD"
                            placeholder="yyyy-mm-dd"
                            @change="checkdate"
                          >
                            <i slot="icon-calendar" class="cursor-pointer">
                              <img src="../../../../assets/images/calendar-input.png" />
                            </i>
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                        <div class="form-group">
                          <label for="endDateCheck">End Date</label>
                          <span
                            class="label-type error event-error ml-2"
                            v-if="endDateError != ''"
                          >
                            Please select End Date.
                          </span>
                          <span
                            class="label-type error event-error ml-2"
                            v-if="endDateErrorGreater != ''"
                          >
                            End Date should be greater than start date
                          </span>
                          <date-picker
                            :open="openEnd"
                            @close="openEnd = false"
                            :editable="false"
                            :clearable="false"
                            @click.native="handleClickEnd"
                            v-model="endDate"
                            valueType="format"
                            format="YYYY-MM-DD"
                            placeholder="yyyy-mm-dd"
                            @change="checkdate"
                          >
                            <i slot="icon-calendar" class="cursor-pointer">
                              <img src="../../../../assets/images/calendar-input.png" />
                            </i>
                          </date-picker>
                        </div>
                      </div>
                      <!-- <div class="row" v-if="allDay == false"> -->
                        <div class="col-sm-12 col-md-6 mt-1 startTime" v-if="allDay == false">
                          <label for="startTimeCheck">Start Time</label>
                          <div class="form-group">
                            <span
                              class="label-type error event-error ml-2"
                              v-if="timeError != ''"
                            >
                              Event Time is Invalid.
                            </span>
                            <div
                              class="input-group time"
                              id="startTimeCheck"
                              data-target-input="nearest"
                            >
                              <vue-timepicker
                                id="startTime"
                                class="time-input"
                                placeholder="Start Time"
                                required="required"
                                v-model="startTime"
                                v-on:change="checkTime"
                                close-on-complete
                                hide-clear-button
                              >
                                <template v-slot:icon>
                                  <img src="../../../../assets/images/clock-input.png" />
                                </template>
                              </vue-timepicker>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 pl-lg-0 mt-1 endTime" v-if="allDay == false">
                          <label for="endTimeCheck">End Time</label>
                          <div class="form-group">
                            <div
                              class="input-group time"
                              id="endTimeCheck"
                              data-target-input="nearest"
                            >
                              <vue-timepicker
                                id="endTime"
                                class="time-input"
                                placeholder="End Time"
                                required="required"
                                v-model="endTime"
                                v-on:change="checkTime"
                                close-on-complete
                                hide-clear-button
                              >
                                <template v-slot:icon>
                                  <img src="../../../../assets/images/clock-input.png" />
                                </template>
                              </vue-timepicker>
                            </div>
                          </div>
                        </div>
                      <!-- </div> -->

                      <div class="col-sm-12 col-md-6 ">
                        <div class="form-group">
                          <label for="entryFee">Entry Fee</label>
                          <input
                              type="text"
                              class="form-control"
                              id="entryFee"
                              v-model="entryFee"
                              @input="validateNumber"
                              @keyup="errorNull"
                              required
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                        <div class="form-group">
                          <label for="deadlineDate">Entry Fee Deadline</label>
                          <!-- <span
                            class="label-type error event-error ml-2"
                            v-if="startDateError != ''"
                          >
                            Please select deadline Date.
                          </span> -->
                          <date-picker
                            :open="openDeadline"
                            @close="openDeadline = false"
                            :editable="false"
                            :clearable="false"
                            @click.native="handleClickDeadline"
                            v-model="deadlineDate"
                            valueType="format"
                            format="YYYY-MM-DD"
                            placeholder="yyyy-mm-dd"
                          >
                            <i slot="icon-calendar" class="cursor-pointer">
                              <img src="../../../../assets/images/calendar-input.png" />
                            </i>
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="directorName">Director Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="directorName"
                            v-model="directorName"
                            @keyup="errorNull"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 ">
                        <div class="form-group">
                          <label for="directorPhone">Director Phone</label>
                          <input
                            type="number"
                            class="form-control"
                            id="directorPhone"
                            v-model="directorPhone"
                            @keyup="errorNull"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                        <div class="form-group">
                          <label for="directorEmail">Director Email</label>
                          <input
                            type="email"
                            class="form-control"
                            id="directorEmail"
                            v-model="directorEmail"
                            @keyup="errorNull"
                            required
                          />
                        </div>
                      </div>
                      

                      <div class="col-12">
                        <div class="form-group">
                          <label for="refundPolicy">Refund Policy</label>
                          <textarea
                            type="text"
                            class="form-control pt-3 mt-2 add-notes"
                            id="refundPolicy"
                            rows="4"
                            v-model="refundPolicy"
                            @keyup="errorNull"
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label for="tournamentFormat">Tournament Format</label>
                          <textarea
                            type="text"
                            class="form-control pt-3 mt-2 add-notes"
                            id="tournamentFormat"
                            rows="4"
                            v-model="tournamentFormat"
                            @keyup="errorNull"
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label for="reminder">Reminder</label>
                          <input
                            type="text"
                            class="form-control"
                            id="reminder"
                            v-model="reminder"
                            @keyup="errorNull"
                            required
                          />
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-6 pl-lg-0 mt-2 mt-md-0">
                        <div class="form-group">
                          <label for="note">Event Notes</label>
                          <textarea
                            type="text"
                            class="form-control pt-3 add-notes event-note"
                            id="note"
                            rows="4"
                            v-model="note"
                            @keyup="errorNull"
                            maxlength="100"
                          ></textarea>
                        </div>
                      </div>

                      <input
                        type="file"
                        class="d-none"
                        id="vue-file-new"
                        v-on:change="addEventAvatar"
                        accept="image/png, image/jpeg"
                      />

                    </div>
                    

                    <!-- <div class="row">
                      <div class="col-12 col-md-6">
                        <div
                          class="form-group d-flex align-items-center justify-content-between"
                        >

                          <label class="mt-2 mt-lg-4">All Day Event</label>
                          <toggle-button
                            class="toogle m-0 mt-2 mt-lg-4"
                            v-model="allDay"
                            @change="showHideTimeDiv"
                            :value="false"
                            :sync="true"
                            :width="34"
                            ref="toggleBtn"
                          />
                        </div>
                      </div>
                    </div> -->

                    
                    
                    <!-- <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="note">Event Notes</label>
                          <textarea
                            type="text"
                            class="form-control pt-3 mt-2 add-notes"
                            id="note"
                            rows="4"
                            v-model="note"
                            @keyup="errorNull"
                            maxlength="100"
                          ></textarea>
                        </div>
                      </div>

                      <input
                        type="file"
                        class="d-none"
                        id="vue-file-new"
                        v-on:change="addEventAvatar"
                        accept="image/png, image/jpeg"
                      />
                    </div> -->
                  </div>
                  <!-- <div
                    class="d-flex align-items-center justify-content-center mb-2"
                  >
                    <button
                      type="button"
                      class="btn btn-primary"
                      block
                      :disabled="firstFormValid"
                      @click="showLocation"
                    >
                      Save & Continue
                    </button>
                  </div> -->
                </div>
              </div>
              <div class="right-content container-fluid">
                <div>
                  <button
                    type="button"
                    class="close-modal d-none d-md-block w-100 text-right pb-2"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeEventModal"
                    @click="resetForm"
                  >
                    <img src="../../../../assets/images/cross.png" alt="" />
                  </button>
                  <ul class="event-menu-list">
                    <li
                      class="list-item"
                      v-bind:class="[openTabEvent == 1 ? 'active' : '']"
                      @click="InPerson"
                    >
                      <img
                        v-if="openTabEvent == 1"
                        src="../../../../assets/images/inperson-blue.png"
                      />
                      <img v-else src="../../../../assets/images/inperson-grey.png" />
                      InPerson
                    </li>
                    <li
                      class="list-item"
                      v-bind:class="[openTabEvent == 2 ? 'active' : '']"
                      @click="onlineContent"
                    >
                      <img
                        v-if="openTabEvent == 2"
                        src="../../../../assets/images/online-blue.png"
                      />
                      <img v-else src="../../../../assets/images/online-grey.png" />
                      Online
                    </li>
                  </ul>
                  <div class="row" v-if="openTabEvent == 1">
                    <div class="col-12">
                      <div class="form-group">
                        <gmap-autocomplete
                          :value="location"
                          placeholder="Enter an address, zipcode, or location"
                          @place_changed="setPlace"
                          ref="autocompleteRef"
                        >
                        </gmap-autocomplete>

                        <!-- <place-autocomplete-field
                          id="location"
                          v-model="location"
                          placeholder="Enter an address, zipcode, or location"
                          name="location"
                          api-key="AIzaSyAEKVEK50teUWnFIJ6vA4ONqe8ZI27V6J8"
                          v-place-autofill:street="street"
                          v-place-autofill:city="city"
                          v-place-autofill:state="state"
                          v-place-autofill:zipcode="zipCode"
                          v-place-autofill:country="country"
                          v-place-autofill:latitude="marker.position.lat"
                          v-place-autofill:longitude="marker.position.lng"
                          @autocomplete-select="errorNull"
                        ></place-autocomplete-field> -->
                      </div>
                    </div>
                    <div class="col-12">
                      <google-map
                        :center="marker.position"
                        :zoom="18"
                        map-style-id="roadmap"
                        :options="mapOptions"
                        style="width: 100vmin; height: 40vmin; margin-bottom: 10px"
                        ref="mapRef"
                        id="map"
                        @click="handleMapClick"
                      >
                        <google-maps-marker
                          :position="marker.position"
                          :clickable="true"
                          :draggable="true"
                          @drag="handleThrottledMarkerDrag"
                          @click="panToMarker"
                        ></google-maps-marker>
                      </google-map>
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="street"
                          v-model="street"
                          @keyup="errorNull"
                          required
                          placeholder="Street Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="openTabEvent == 1">
                    <div class="col-12 col-md-4 pr-md-2">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="city"
                          v-model="city"
                          @keyup="errorNull"
                          required
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-4 px-md-0">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="state"
                          v-model="state"
                          @keyup="errorNull"
                          required
                          placeholder="State"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-4 pl-md-2">
                      <div class="form-group">
                        <input
                          type="number"
                          class="form-control"
                          id="zipCode"
                          v-model="zipCode"
                          @keyup="errorNull"
                          required
                          placeholder="ZipCode"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="openTabEvent == 2">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <select
                          class="form-control form-control-lg"
                          id="medium"
                          v-model="medium"
                          @change="errorNull"
                        >
                          <option value="" selected disabled>Select Medium</option>
                          <option
                            v-for="option in mediumOptions"
                            :key="option.id"
                            :value="option.id"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          id="Url"
                          v-model="Url"
                          @keyup="errorNull"
                          @input="change($event)"
                          @change="change($event)"
                          required
                          placeholder="Enter URL"
                        />

                        <div class="error" v-if="!isValid">URL is Invalid</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="event-btns-wrapper">
                  <button
                    v-if="this.isDisabledEvent == false"
                    type="button"
                    class="btn btn-primary w-100"
                    v-on:click="createEvent(0)"
                    :disabled="isLoadingArray[0]"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Save</span>
                  </button>
                  <button
                    v-else-if="this.isDisabledEvent == true"
                    type="button"
                    class="btn btn-primary-disabled w-100"
                    :disabled="(true, isLoadingArray[0])"
                  >
                    <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                    <span v-else>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueTimepicker from "vue2-timepicker";
import axios from "axios";
// import * as firestoreService from "../../../../database/firestore";
import { parseTimestamp } from "../../../../utils/dates";
import $ from "jquery";
// import Multiselect from "vue-multiselect";
let usTime = require("../../../../assets/js/usTimeZone");
import _ from 'lodash';
import { clipperBasic, clipperPreview } from "vuejs-clipper";
let eventType = require("../../../../assets/js/eventType");

export default {
  name: "CreateAssociationEventDetailModal",
  
  data() {
    return {
      open: false,
      openDeadline: false,
      openEnd: false,
      userName: localStorage.getItem("userName"),
      isDisabledEvent: true,
      showLoaderEvent: 0,
      eventName: null,
      eventType: "",
      entryFee: "",
      deadlineDate: "",
      refundPolicy: "",
      tournamentFormat: "",
      reminder: "",
      association: "",
      location: null,
      directorEmail: "",
      directorPhone: "",
      directorName: "",
      exactEndTime: "11:00 AM",
      exactStartTime: "10:00 AM",
      exactStartDate: "",
      exactEndDate: "",
      startDate: "",
      endDate: "",
      startTime: "10:00",
      endTime: "11:00",
      city: "",
      state: "",
      zipCode: "",
      street: "",
      country: "",
      lat: "",
      lng: "",
      note: "",
      allDay: false,
      rawData: "",
      eventAvatar: [],
      eventFinalAvatar: "",
      options: [],
      eventTypeOptions: eventType,
      mediumOptions: [],
      marker: { position: { lat: 10, lng: 10 } },
      markers: [],
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: false,
      },
      startDateError: "",
      endDateError: "",
      endDateErrorGreater: "",
      exactStartDateformat: "",
      exactEndDateformat: "",
      timeError: "",
      currentPlace: null,
      onlineStatus: false,
      checkedOnline: false,
      Url: "",
      medium: "",
      isValid: true,
      associationsAdd: [],
      isLoading: false,
      regex: /^(http|https):\/\/[w\d]+\.[\w](\/[\w\d]+)?$/,
      firstFormValid: true,
      Storage_URL: this.$googleMapApi,
      description: "Singapore",
      latLng: {},
      timeZoneList: usTime,
      time_zone: "",
      deviceTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      openTabEvent: 1,
      isLoadingArray: [],
    };
  },
  components: {
    // Multiselect,
    clipperBasic,
    clipperPreview,
    VueTimepicker,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    formattedDeviceTimeZone() {
      const gmtOffset = this.getDynamicGmtOffset(this.deviceTimeZone);
      return gmtOffset ? `${gmtOffset} ${this.deviceTimeZone}` : this.deviceTimeZone;
    }
  },
  watch: {
    
    city(newValue) {
      this.city = newValue;
      this.errorNull();
    },
    state(newValue) {
      this.state = newValue;
      this.errorNull();
    },
    street(newValue) {
      this.street = newValue;
      this.errorNull();
    },
    zipCode(newValue) {
      this.zipCode = newValue;
      this.errorNull();
    }
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: Number,
      required: true,
    },
    associationData: {
      type: Object,
    },
  },
  methods: {
    getDynamicGmtOffset() {
      const date = new Date();
      const utcOffset = date.getTimezoneOffset(); // Offset in minutes from UTC
      const hoursOffset = Math.floor(Math.abs(utcOffset) / 60);
      const minutesOffset = Math.abs(utcOffset) % 60;

      // Determine the sign for the offset
      const sign = utcOffset < 0 ? '+' : '-';

      // Format the GMT offset
      return `(UTC${sign}${String(hoursOffset).padStart(2, '0')}:${String(minutesOffset).padStart(2, '0')})`;
    },
    setDefaultTimeZone() {
        this.time_zone = this.formattedDeviceTimeZone; // use a default format
    },
    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    handleClickDeadline(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.openDeadline = true;
      }
    },
    handleClickEnd(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.openEnd = true;
      }
    },
    usePlace() {
      if (this.location) {
        this.markers.push({
          position: {
            lat: this.location.geometry.location.lat(),
            lng: this.location.geometry.location.lng(),
          },
        });
        this.location = null;
      }
    },
    InPerson() {
      this.$root.$emit("openTabTeamEvent", 1, 0);
      this.medium = "";
      this.Url = "";
      if (this.medium == "" && this.Url == "") {
        this.isDisabledEvent = true;
      } else {
        this.isDisabledEvent = false;
      }
      this.errorNull();
      this.checkTab(1);
    },
    onlineContent() {
      this.$root.$emit("openTabTeamEvent", 2, 0);
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.street = "";
      this.errorNull();
      this.checkTab(2);
    },
    resetForm: function () {
      document.getElementById("vue-file-new").value = "";
    },
    handleThrottledMarkerDrag: _.throttle(function(event) {
    this.handleMarkerDrag(event);
  }, 50),
    resetForms: function () {
      this.getMedium();
      document.getElementById("vue-file-new").value = "";
      this.exactEndTime = "11:00 AM";
      this.exactStartTime = "10:00 AM";
      this.startTime = "10:00";
      this.endTime = "11:00";
      var element = this.$refs.mydetailTab;
      if (element) {
        element.click();
      }
      this.isDisabledEvent = true;
      this.firstFormValid = true;
      this.showLoaderEvent = 0;
      this.eventName = "";
      this.eventType = "";
      this.entryFee = "";
      this.deadlineDate = "";
      this.refundPolicy = "",
      this.tournamentFormat = "";
      this.reminder = "";
      this.association = "";
      this.directorEmail= "",
      this.directorPhone= "",
      this.directorName= "",
      this.location = "";
      this.exactEndTime = "11:00 AM";
      this.exactStartTime = "10:00 AM";
      this.exactStartDate = "";
      this.exactEndDate = "";
      this.startDate = "";
      this.endDate = "";
      this.startTime = "10:00";
      this.endTime = "11:00";
      this.city = "";
      this.state = "";
      this.zipCode = "";
      this.street = "";
      this.lan = "";
      this.long = "";
      this.note = "";
      this.allDay = false;
      this.rawData = "";
      this.eventAvatar = [];
      this.eventFinalAvatar = "";
      (this.time_zone = ""), (this.startDateError = "");
      this.endDateError = "";
      this.endDateErrorGreater = "";
      this.timeError = "";

      this.exactStartDateformat = "";
      this.exactEndDateformat = "";
      (this.medium = ""), (this.Url = "");
      this.allDay = false;
      
    },
    validateNumber(event) {
      let value = event.target.value;

      // Allow only digits and a single decimal point
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

      // Split the value into parts before and after the decimal point
      const parts = value.split('.');

      // Limit the integer part to 4 digits
      if (parts[0].length > 4) {
        parts[0] = parts[0].substring(0, 4);
      }

      // Limit the decimal part to 2 digits
      if (parts[1] && parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
      }

      // Recombine the parts
      this.entryFee = parts.join('.');
    },
    errorNull: function () {
      // this.validatefirstForm();
      if (this.checkedOnline == false) {
        // Call checkTime if allDay is false and exactStartTime or exactEndTime is empty
        if (this.allDay === false && (this.exactStartTime === "" || this.exactEndTime === "")) {
          this.checkTime();
        }
        if (
          this.city == "" ||
          this.state == "" ||
          this.zipCode == "" ||
          this.street == "" ||
          this.state == null ||
          this.city == null ||
          this.street == null ||
          this.zipCode == null ||
          this.eventName == "" ||
          this.eventType == "" ||
          this.entryFee == "" ||
          this.deadlineDate == "" ||
          this.refundPolicy == "" ||
          this.tournamentFormat == "" ||
          this.reminder == "" ||
          // this.association == "" ||
          this.timeError != "" ||
          this.startDate == "" ||
          this.endDate == "" ||
          this.endDateErrorGreater != "" ||
          // this.startTime == "" ||
          // this.endTime == "" ||
          (this.allDay == false && (this.exactStartTime == "" || this.exactEndTime == ""))
        ) {
          this.isDisabledEvent = true;
        } else {
          this.isDisabledEvent = false;
        }
      }
      if (this.checkedOnline == true) {
        // Call checkTime if allDay is false and exactStartTime or exactEndTime is empty
        if (this.allDay === false && (this.exactStartTime === "" || this.exactEndTime === "")) {
          this.checkTime();
        }

        // Combine all other conditions to determine if the event should be disabled
        if (
          this.medium == "" ||
          this.medium == null ||
          this.Url == "" ||
          this.Url == null ||
          this.isValid == false ||
          this.eventName == "" ||
          this.eventType == "" ||
          this.timeError != "" ||
          this.startDate == "" ||
          this.endDate == "" ||
          this.entryFee == "" ||
          this.deadlineDate == "" ||
          this.refundPolicy == "" ||
          this.tournamentFormat == "" ||
          this.reminder == "" ||
          this.endDateErrorGreater != "" ||
          (this.allDay === false && (this.exactStartTime === "" || this.exactEndTime === "")) // Include this condition as part of the overall validation
        ) {
          this.isDisabledEvent = true;
        } else {
          this.isDisabledEvent = false;
        }
      }
    },

    checkTime: function () {
      if (this.startTime != "") {
        var timeSplit = this.startTime.split(":"),
          hours,
          minutes,
          meridian;
        hours = timeSplit[0];
        minutes = timeSplit[1];
        if (hours > 12) {
          meridian = "PM";
          hours -= 12;
        } else if (hours < 12) {
          meridian = "AM";
          if (hours == 0) {
            hours = 12;
          }
        } else {
          meridian = "PM";
        }
        this.exactStartTime = hours + ":" + minutes + " " + meridian;
      }

      if (this.endTime != "") {
        var timeSplitend = this.endTime.split(":"),
          hoursend,
          minutesend,
          meridianend;
        hoursend = timeSplitend[0];
        minutesend = timeSplitend[1];
        if (hoursend > 12) {
          meridianend = "PM";
          hoursend -= 12;
        } else if (hoursend < 12) {
          meridianend = "AM";
          if (hoursend == 0) {
            hoursend = 12;
          }
        } else {
          meridianend = "PM";
        }
        this.exactEndTime = hoursend + ":" + minutesend + " " + meridianend;
      }
      this.checkTimeDate();
    },

    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      if (!place) return;
      this.$refs.autocompleteRef.$el.classList.add("custom-dropdown-open");
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.street = place.formatted_address;
      this.location = place.formatted_address;
      const addressComponents = place.address_components;

      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes("locality")) {
          this.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          this.state = component.short_name;
        } else if (types.includes("postal_code")) {
          this.zipCode = component.long_name;
        }
      }
      this.panToMarker();
    },
    // Check dates
    checkdate: function () {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Start Date
      
      if (this.startDate.length == 10) {
        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];

        var varDate = startMonth + "-" + startDay + "-" + startYear;
        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.exactStartDate = month[startMonth - 1] + " " + startDay + ", " + startYear;
      }

      // End Date-----
      if (this.endDate.length == 10) {
        const endDateArray = this.endDate.split("-");
        var endMonth = endDateArray[1];
        var endDay = endDateArray[2];
        var endYear = endDateArray[0];

        var varDatee = endMonth + "-" + endDay + "-" + endYear;
        var endDateConvert = new Date(varDatee);
        if (endDateConvert < startDateConvert) {
          this.endDateError = "";
          this.endDateErrorGreater = "on";
          this.exactEndDate = "";
          this.isDisabledEvent = true;
        } else {
          this.exactEndDateformat = endDateConvert;
          this.endDateError = "";
          this.endDateErrorGreater = "";
          this.exactEndDate = month[endMonth - 1] + " " + endDay + ", " + endYear;
        }
      }

      this.checkTimeDate();
    },

    // check timeDate
    checkTimeDate: function () {
      if (this.exactStartDate === "" && this.exactEndDate !== "") {
    this.startDateError = "on";
  } else {
    this.startDateError = "";
  }

  if (this.exactEndDate === "" && this.exactStartDate !== "") {
    this.endDateError = "on";
  } else {
    this.endDateError = "";
  }

      if (this.exactStartTime != "" && this.exactStartDate != "") {
        this.errorNull();
      }
    },

    // Upload Image
    openinput: function () {
      document.getElementById("vue-file-new").click();
    },

    addEventAvatar: function (event) {
      const self = this;
      var fileInput = document.getElementById("vue-file-new");
      var file_ = fileInput.value;
      var files = event.target.files;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file_)) {
        alert("Please upload file having extensions .jpeg/.jpg/.png/.gif only.");
        fileInput.value = "";
        return false;
      } else {
        const file_ = files[0];
        this.eventAvatar.push({
          target: event.target.files[0],
          fileName: file_.name,
          link: URL.createObjectURL(file_),
        });
        let reader = new FileReader();
        reader.onload = (e) => {
          self.rawData = e.target.result;
        };
        reader.readAsDataURL(file_);
        this.errorNull();
      }
    },
    dataReset(){
      this.startDateError = "";
      this.endDateError = "";
      this.endDateErrorGreater = "";
    },
    dataURItoBlob: function (dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    // Create Event
    async createEvent(index) {
      // Add Avator to storage
      this.$set(this.isLoadingArray, index, true);
      this.isDisabledEvent = true;
      var formData = new FormData();

      if (this.eventAvatar.length > 0) {
        this.eventAvatar.forEach((value, index) => {
          formData.append("avatar[" + index + "]", value.target);
        });
      }

      // Convert user array to string
      // const userCIDArray = [];
      // this.selectedRoomTeam.users.forEach((user) => {
      //   userCIDArray.push(user.c_id);
      // });
      // var userString = userCIDArray.toString();
      // ---------------------------------

      this.startTime = this.allDay == true ? "00:00" : this.startTime;
      this.exactStartTime = this.allDay == true ? "12:00 AM" : this.exactStartTime;
      this.endTime = this.allDay == true ? "24:00" : this.endTime;
      this.exactEndTime = this.allDay == true ? "12:00 PM" : this.exactEndTime;
      formData.append("eventName", this.eventName ? this.eventName : "");
      // formData.append("users", userString);
      // formData.append("team_id", this.selectedRoomTeam.roomId);
      formData.append("eventType", this.eventType);
      formData.append("director_email", this.directorEmail);
      formData.append("director_phone", this.directorPhone);
      formData.append("director_name", this.directorName);
      formData.append("entry_fee", this.entryFee);
      formData.append("entry_fee_deadline", this.deadlineDate);
      formData.append("refund_policy", this.refundPolicy);
      formData.append("tournament_format", this.tournamentFormat);
      formData.append("reminder", this.reminder);
      formData.append("association", this.associationData.id ? this.associationData.id : "");
      formData.append("startDateForField", this.startDate);
      formData.append("endDateForField", this.endDate); 
      formData.append("startDate", this.exactStartDateformat);
      formData.append("endDate", this.exactEndDateformat);
      formData.append("startTime", this.startTime);
      formData.append("endTime", this.endTime);
      formData.append("exactStartDate", this.exactStartDate);
      formData.append("exactEndDate", this.exactEndDate);
      formData.append("exactStartTime", this.exactStartTime);
      formData.append("exactEndTime", this.exactEndTime);
      formData.append("allDay", this.allDay);
      formData.append("note", this.note ? this.note : "");
      formData.append("time_zone", this.time_zone ? this.time_zone : this.formattedDeviceTimeZone);

      formData.append("createdByName", this.userName);
      if (this.checkedOnline == false) {
        formData.append("lan", this.marker.position.lat);
        formData.append("long", this.marker.position.lng);
        formData.append("address", this.street ? this.street : "");
        formData.append("location", this.location ? this.location : "");
        formData.append("city", this.city ? this.city : "");
        formData.append("state", this.state ? this.state : "");
        formData.append("zipCode", this.zipCode ? this.zipCode : "");
        formData.append("medium_id", "");
        formData.append("Url", "");
        formData.append("online", this.onlineStatus);
      } else if (this.checkedOnline == true) {
        formData.append("medium_id", this.medium ? this.medium : "");
        formData.append("Url", this.Url ? this.Url : "");
        formData.append("online", this.onlineStatus);
        formData.append("location", "");
        formData.append("city", "");
        formData.append("state", "");
      }
      formData.append("createdByDate", parseTimestamp(new Date(), "DD/MM/YY"));
      await axios
        .post(process.env.VUE_APP_API_URL + "associationEvent/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // firestoreService.updateRoom(this.selectedRoomTeam.roomId, {
          //   eventCount: this.selectedRoomTeam.eventCount + 1,
          //   // lastUpdated: new Date(),
          // });
          if(response.data.statusCode === 404) {
            this.$set(this.isLoadingArray, index, false);
              this.$alert(response.data.message,"Error")
              this.$router.push({ name: "Teams" });
          }else if(response.data.statusCode === 403) {
            this.$set(this.isLoadingArray, index, false);
              this.$alert(response.data.message,"Error")
          }
          if(response.data.statusCode !== 404){
            this.$root.$emit("fetchAssociationEvents");
          }
          // this.$root.$emit("ChatSettings", 0);
           this.resetForm();
           this.dataReset();
          var elem = this.$refs.closeEventModal;
          elem.click();
          this.$set(this.isLoadingArray, index, false);
        });
    },
    asyncFind(searchQuery) {
      this.isLoading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${
            searchQuery ? searchQuery : ""
          }`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationsAdd = response.data.data.association;
          this.isLoading = false;
        });
    },

    getMedium() {
      axios.get(`${process.env.VUE_APP_API_URL}getMedium`).then((response) => {
        if (response.data.statusCode == 200) {
          this.mediumOptions = response.data.data.medium;
        } else {
          this.errorMessage = response.data.message;
        }
      });
    },
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.panToMarker();
      });
    },
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[1]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (results[i].address_components[j]?.types[0] == "locality") {
                    window.test.city = results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route" ||
                results[i]?.types[0] == "neighborhood"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] == "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street = results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state = results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (results[i].address_components[j]?.types[0] == "postal_code") {
                    window.test.zipCode = results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
      this.errorNull();
    },
    getCity(addressArray) {
      let city = "";
      for (let i = 0; i < addressArray.length; i++) {
        // eslint-disable-next-line
        if (addressArray[i].types[0] && "locality" == addressArray[i].types[0]) {
          city = addressArray[i].long_name;
          return city;
        }
      }
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      window.test = this;
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      var geocoder = new window.google.maps.Geocoder();
      var latlng = this.marker.position;
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === "OK") {
          if (results[0]) {
            for (let i = 0; i < results.length; i++) {
              // eslint-disable-next-line
              window.test.location = results[2].formatted_address;
              if (results[i]?.types[0] == "locality") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (results[i].address_components[j]?.types[0] == "locality") {
                    window.test.city = results[i].address_components[j].long_name;
                  }
                }
                // this.city = results[i].long_name;
              }
              if (
                results[i]?.types[0] == "premise" ||
                results[i]?.types[0] == "street_address" ||
                results[i]?.types[0] == "route"
              ) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] == "route" ||
                    results[i].address_components[j]?.types[0] == "street_address" ||
                    results[i].address_components[j]?.types[0] == "premise"
                  ) {
                    window.test.street = results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "administrative_area_level_1") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (
                    results[i].address_components[j]?.types[0] ==
                    "administrative_area_level_1"
                  ) {
                    window.test.state = results[i].address_components[j].long_name;
                  }
                }
              }
              if (results[i]?.types[0] == "postal_code") {
                for (let j = 0; j < results[i].address_components.length; j++) {
                  if (results[i].address_components[j]?.types[0] == "postal_code") {
                    window.test.zipCode = results[i].address_components[j].long_name;
                  }
                }
              }
            }
          } else {
            window.alert("No results found");
          }
        }
      });
      this.errorNull();
    },
    showLocation() {
      var elem = this.$refs.locationTabGet;
      elem.click();
      this.$refs.topSection.scrollTo(0, 0);
      this.geolocate();
    },
    validatefirstForm() {
      // if (
      //   this.eventName == "" ||
      //   this.exactStartDate == "" ||
      //   this.exactEndDate == "" ||
      //   this.eventType == "" ||
      //   this.association == "" ||
      //   this.timeError != ""
      // ) {
      //   this.firstFormValid = true;
      //   this.isDisabledEvent = true;
      // } else {
      if (
        (this.exactStartTime == "" || this.exactEndTime == "") &&
        this.allDay == false
      ) {
        // this.firstFormValid = true;
        this.isDisabledEvent = true;
      } else {
        this.firstFormValid = false;
      }
      // }
    },
    checkTab(check) {
      if (check == 1) {
        this.onlineStatus = false;
      } else {
        this.onlineStatus = true;
      }
      if (this.onlineStatus == true) {
        this.checkedOnline = true;
      } else {
        this.checkedOnline = false;
        this.Url = "";
      }
    },
    change(e) {
      const url = e.target.value;
      this.isURLValid(url);
    },
    isURLValid(inputUrl) {
      var regx = new RegExp(
        "^" +
          // protocol identifier
          "(?:(?:https?|http)://)" +
          // user:pass authentication
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          // IP address exclusion
          // private & local networks
          "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
          "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
          "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broacast addresses
          // (first & last IP address of each class)
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          // host name
          "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
          // domain name
          "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
          // TLD identifier
          "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
          ")" +
          // port number
          "(?::\\d{2,5})?" +
          // resource path
          "(?:/\\S*)?" +
          "$",
        "i"
      );
      this.isValid = regx.test(inputUrl);
    },
    showHideTimeDiv() {
      if (this.allDay == true) {
        $(".startTime").hide();
        $(".endTime").hide();
        // this.allDay = true;
        this.exactStartTime = "";
        this.startTime = "";
        this.endTime = "";
        this.exactEndTime = "";
        // this.isDisabledEvent = false;
      } else if (this.allDay == false) {
        $(".startTime").show();
        $(".endTime").show();
        this.exactStartTime = "10:00 AM";
        this.startTime = "10:00";
        this.endTime = "11:00";
        this.exactEndTime = "11:00 AM";
      }
      // this.validatefirstForm();
    },

    showPro() {
      this.$router.push({ name: "GoPro" });
    },
  },
  mounted() {
    this.setDefaultTimeZone();
    // this.asyncFind();
    this.geolocate();
    this.$root.$on("openTabTeamEvent", (id) => {
      this.openTabEvent = id;
    });
  },
};
</script>

<style scoped>
.event-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 15px;
}

.label-type {
  font-size: 10px;
  font-weight: 600;
  color: #6c757d;
}
div.form-group input[type="checkbox"] {
  width: 16px;
}
.all-day {
  font-size: 1rem;
}

.error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: -20px;
  padding: 5px 10px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
}
.start-error{
    left: 60px!important;
}
.nav-tabs {
  list-style-type: none;
}
.nav-tabs li a {
  display: inline-block;
  padding: 12px 0;
  margin: 0 20px 0 0;
  font-weight: 600;
  font-size: 16px;
  color: #869cb1 !important;
}
.nav-tabs li a.active {
  color: #188ef5 !important;
  border: none;
  border-bottom: 3px solid #188ef5;
}
.nav-tabs .nav-link {
  border: none;
}
.event-image {
  display: flex;
  gap: 10px;
}
.event-image-edit {
  width: 250px;
  cursor: pointer;
  height: 250px;
  position: relative;
  text-align: center;
  border: 2px solid #f1f3f6;
}
.event-image-edit .default-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.event-image-edit img,
.new-event-image img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.input-group-text {
  border-radius: 0 11px 11px 0;
}
div.form-group .add-notes {
  font-size: 14px;
}
.event-note{
  height: 50px;
}
.event-detail-btn {
  background-color: #188ef5;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  padding: 10px 12px;
  border: none;
  width: 150px;
  margin: 0 auto;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.event-detail-btn:hover {
  color: #ffffff;
}

.nav-tabs .nav-link:hover {
  border-color: none;
}
.nav-tabs .nav-link.active {
  border-bottom: 3px solid #188ef5;
}
.vue-map-container,
.vue-map-container .vue-map {
  width: 100% !important;
}
.join-footer .btn {
  background-color: #188ef5;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1rem;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
.event-error {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 10px;
  position: absolute;
  padding: 3px 8px;
  z-index: 99;
  border-radius: 5px;
  background-color: #ff5b66;
  top: 2px;
}
.event-detail label {
  font-size: 0.8rem;
}
input[type="date"]::-webkit-calendar-picker-indicator::before {
  background-color: rgba(0, 0, 0, 0.37);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../../../../assets/images/calendar-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../../../../assets/images/clock-input.png");
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.profile-photo-edit-icon-wrapper {
  position: absolute;
  top: 82%;
  background-color: #e5f2fe;
  right: 4%;
  width: 35px;
  height: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.profile-photo-edit-icon-wrapper img {
  width: 20px;
}
.pac-container {
  z-index: 999999 !important;
  display: block !important;
}
.custom-gmap-autocomplete {
  display: block !important;
  z-index: 999999 !important;
}
.gm-style img {
  transition: transform 0.3s ease-in-out;
}
</style>
